<template>
  <a-config-provider :locale="ru_RU">
    <component :is="layout">
      <div id="app">
        <router-view />
      </div>
    </component>
  </a-config-provider>
</template>

<script>
import "regenerator-runtime";
import ru_RU from "ant-design-vue/lib/locale-provider/ru_RU";
import packageJson from "../package.json";
import FourlapsLayout from "@/layouts/4laps/FourLapsLayout";
import DefaultLayout from "@/layouts/DefaultLayout";
import { logDebug } from "@/helpers/logHelper";

export default {
  components: {
    FourlapsLayout,
    DefaultLayout,
  },
  data() {
    return {
      ru_RU,
    };
  },
  computed: {
    layout() {
      logDebug("PACKAGE_VERSION: " + packageJson);
      logDebug("hi");
      return process.env.VUE_APP_TYPE.toLowerCase() + "-layout";
    },
  },
  metaInfo() {
    return {
      title: "test meta data with vue",
      meta: [
        {
          "http-equiv": "Content-Security-Policy",
          content: "upgrade-insecure-requests"
        }
      ]
    };
  }
};
</script>

<style>
@import "./magnific-popup.css";

.auth-center {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-color: #fcffe7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: calc(-1 * (100vw - 100%));
  overflow-x: hidden;
}

.white_block .right .middle .tabs .full_reviews .head a.nothing:after {
  content: "=";
  margin-top: 0px;
  margin-left: 3px;
}

.white_block .right .middle .full_users .head a.nothing:after {
  content: "=";
  margin-top: 0px;
  margin-left: 3px;
}

.white_block .right .middle .full_objects .head a.nothing:after {
  content: "=";
  margin-top: 0px;
  margin-left: 3px;
}

.white_block
  .right
  .middle
  .tabs
  .full_reviews
  .head
  .head_rest
  a.filter-off:after {
  content: url(./assets/img/rest_gray.svg);
  margin-left: 3px;
}
</style>
